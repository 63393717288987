<template>
  <div class="app-container" v-loading.fullscreen.lock="fullscreenLoading">
    <header-menu
      :showTitle="false"
      logoTitle="个人中心"
      logoRouter="/student"
      :menuItems="menuItems"
    />
    <div class="app-content-wrap">
      <router-view />
    </div>
    <footer-info />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderMenu from "@/components/Common/HeaderMenu.vue";
import { AuditStatusEnum, StaffTypeEnum } from "@/utils/constant";
import FooterInfo from "@/components/Common/FooterInfo";
export default {
  components: { HeaderMenu, FooterInfo },
  name: "StudentLayout",
  data() {
    return {
      fullscreenLoading: false,
      menuItems: [
        { title: "工作台", path: "/student" },
        { title: "投递记录", path: "/student/job" },
        { title: "我的课程", path: "/student/recruitnews" },
        { title: "在线沟通", path: "/student/school" }
        // {
        //   title: "更多功能",
        //   path: "",
        //   submenus: [
        //     { title: "课程资源", path: "/student/course" },
        //     { title: "岗位实践", path: "/student/practice/approve" },
        //     { title: "客户管理", path: "/student/cooperation" },
        //     { title: "人才搜索", path: "/student/talent" }
        //   ]
        // }
      ]
    };
  },
  computed: {},
  methods: {},
  created() {}
};
</script>
